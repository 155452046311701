<template>
  <v-layout row justify-center>

    <v-dialog
      v-model="show"
      max-width="290"
      :persistent="persistent"
      @keydown.enter="closeDialog(true)"
      hide-overlay
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">{{header}}</v-card-title>

        <v-card-text v-html="text">
            {{text}}
        </v-card-text>

        <v-card-actions>
          <v-layout>
            <v-flex xs12 md12 lg12 class="text-xs-center">
                <v-btn block
                color="primary"
                flat
                @click="closeDialog(true)"
                >
                {{agree_button_text}}
                </v-btn>
            </v-flex>
        </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
  export default {
      props:
      {
          persistent:
          {
              type: Boolean,
              required: false,
              default: true
          },
          header:
          {
              type: String,
              required: false,
              default: ""
          },
          text:
          {
              type:String,
              required: false,
              default:""
          },
          abort_button_text:
          {
              type: String,
              required: false,
              default:"Avbryt"
          },
          agree_button_text:
          {
              type: String,
              required: false,
              default: "OK"
          },
          value:
          {
          }
      },
    data ()
    {
      return {
        show: this.value
      }
    },
    watch:
    {
      value: function (val)
      {
        this.show = val;
      }
    },
    methods:
    {
      closeDialog:
      function(retval)
      {
          this.$emit('close-dialog-event',true);
      }
    }
  }
</script>
