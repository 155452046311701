<template>

<div>

    <form id="history_submit_form" method="post" action="realtime_history" target="window.open('', '_blank');">
        <input type="hidden" name="date" v-model="history_date" />
        <input type="hidden" name="reference" v-model="history_reference" />
        <input type="hidden" name="_token" id="token" v-model="csrf_token" />
    </form>


  <v-layout row justify-center>
    <v-dialog v-model="show" fullscreen transition="dialog-bottom-transition" @keydown.esc="closeDialog">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="closeDialog">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>{{header}} {{company_name}} ({{routedata_set_name}})</v-toolbar-title>
        </v-toolbar>

        <v-dialog
         v-model="show_realtime_forecast"
         persistent
         hide-overlay>
          <v-card>
            <v-card-title class="headline">Sanntidsprognoser</v-card-title>

            <v-card-text>

            <v-data-table
              :headers="forecast_headers"
              no-data-text="Ingen prognoser ble funnet"
              :items="forecast"
              :loading="loading"
              :pagination.sync="pagination"
              class="elevation-1"
            >
              <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
              <template slot="items" slot-scope="props">
                <td class="text-xs-center">{{ props.item.expected_arrival }}</td>
                <td class="text-xs-center"><b>{{ props.item.actual_arrival }}</b></td>
                <td class="text-xs-center">{{ props.item.expected_departure }}</td>
                <td class="text-xs-center"><b>{{ props.item.actual_departure }}</b></td>
                <td class="text-xs-center">{{ props.item.block }}</td>
                <td class="text-xs-center"><b>{{ props.item.stop_point_name }}</b></td>
                <td class="text-xs-center">{{ props.item.stop_point_number }}</td>
                <td class="text-xs-center">{{ props.item.stop_point_sequence_number }}</td>
                <td class="text-xs-center">{{ props.item.timestamp }}</td>
              </template>

            </v-data-table>

            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="orange darken-1"
                flat="flat"
                @click="show_realtime_forecast = false"
              >
                Lukk
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="loading"
          persistent
          hide-overlay
          width="300"
        >
          <v-card
            color="primary"
            dark
          >
            <v-card-text>
              {{loader_text}}
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>




    <v-layout row wrap>

        <v-flex sm4 md4 lg4>
            <v-date-picker landscape v-model="pickerDate"
            first-day-of-week="1"
                        class="mt-3" show-week locale="nb">
            </v-date-picker>
        </v-flex>

        <v-flex sm7 md7 lg7>

        <div>
            <v-card-title>
                <b>Turoversikt</b>
                <v-spacer></v-spacer>
                <v-text-field
                v-model="trip_search"
                append-icon="search"
                label="Filtrer turer"
                single-line
                hide-details
                ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :search="trip_search"
              no-data-text="Ingen turer ble funnet"
              :items="trips"
              :loading="loading_trips"
              class="elevation-1"
            >
              <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
              <template slot="items" slot-scope="props">
                <td class="text-xs-center"><b>{{ props.item.line_number }}</b></td>
                <td class="text-xs-center">{{ props.item.trip_number }}</td>
                <td class="text-xs-center">{{ props.item.departure_time }}</td>
                <td class="text-xs-center">{{ props.item.destination_text }}</td>
                <!--td class="text-xs-center">{{ props.item.destination_number }}</td-->
                <td class="text-xs-center">{{ props.item.block_number }}</td>
                <td class="text-xs-center">{{ props.item.reference }}</td>
                <td class="text-xs-center">
                    <v-tooltip top>
                      <template #activator="data">
                        <v-icon color="primary" v-on="data.on" style="cursor: pointer;" @click="getTripData(props.item)" :disabled="loading == true">event_note</v-icon>
                      </template>
                      <span>Sanntidsprognoser</span>
                    </v-tooltip>
                     <v-tooltip top>
                       <template #activator="data">
                         <v-icon color="primary" v-on="data.on" style="cursor: pointer;" :disabled="loading == true">map</v-icon>
                       </template>
                       <span>Vis trase</span>
                     </v-tooltip>
                     <v-tooltip top>
                       <template #activator="data">
                         <v-icon color="primary" v-on="data.on" style="cursor: pointer;"
                          @click="openTripHistory(props.item)"
                          :disabled="((pickerDate == today) &&
                                     (props.item.departure_time > time)) ||
                                     pickerDate > today || loading == true">history</v-icon>
                       </template>
                       <span>Vis historikk</span>
                     </v-tooltip>
              </td>
              </template>
              <v-alert slot="no-results" :value="true" color="error" icon="warning">
                Søket ditt etter "{{ trip_search }}" ga ingen treff.
              </v-alert>
            </v-data-table>
        </div>
    </v-flex>
    </v-layout>

      </v-card>

    </v-dialog>
  </v-layout>
</div>
</template>

<script>
export default
{
    data: function ()
    {
        return {
              map_url: 'https://tile.tftservice.no/{z}/{x}/{y}.png',
              center: [69.666, 18.946],
              bounds: null,
              zoom: 12,
              mapOptions: {
                  attributionControl: false,
                  trackResize: true,
                  boxZoom: true,
                  doubleClickZoom: false,
                  dragging: true
              },
              csrf_token:document.head.querySelector("[name=csrf-token]").content,
              pickerDate:moment().format("YYYY-MM-DD"),
              loading_trips:false,
              header:"Planlagt trafikk for ",
              loader_text:"",
              trips:[],
              forecast:[],
              show_realtime_forecast:false,
              show_realtime_history:false,
              pagination: {  sortBy: "stop_point_sequence_number"},
              loading:false,
              trip_search : '',
              history_date:"sdsd",
              history_reference:"REFERE1111NCE",
              time:'',
              current_date: moment().format("YYYY-MM-DD"),
              today: moment().format("YYYY-MM-DD"),
              forecast_headers:[{text:'Forventet ankomst',align:'center',sortable:true,value:'expected_arrival'},
                             {text:'Faktisk ankomst',align:'center',sortable:true,value:'actual_arrival'},
                             {text:'Forventet avgang',align:'center',sortable:true,value:'expected_departure'},
                             {text:'Faktisk avgang',align:'center',sortable:true,value:'actual_departure'},
                             {text:'Vognløp',align:'center',sortable:true,value:'block'},
                             {text:'Holdeplass',align:'center',sortable:true,value:'stop_point_name'},
                             {text:'Holdeplass ID',align:'center',sortable:true,value:'stop_point_number'},
                             {text:'Sekvensnummer',align:'center',sortable:true,value:'stop_point_sequence_number'},
                             {text:'Oppdateringstidspunkt',align:'center',sortable:true,value:'timestamp'}],

              headers: [{text:'Linje',align:'center',sortable:true,value:'line_number'},
                             {text:'Tur',align:'center',sortable:true,value:'trip_number'},
                             {text:'Avgang',align:'center',sortable:true,value:'departure_time'},
                             {text:'Destinasjon',align:'center',sortable:true,value:'destination_text'},
                             //{text:'Destinasjonsnummer',align:'center',sortable:true,value:'destination_number'},
                             {text:'Vognløp',align:'center',sortable:true,value:'block_number'},
                             {text:'Referanse',align:'center',sortable:true,value:'reference'},
                             {text:'',align:'center',sortable:false,value:'browse_block'}]
        }
    },
  props:
  {
      show:
      {
          type: Boolean,
          required: true
      },
      routedata_id:
      {
          type: Number,
          required: false
      },
      company_name:
      {
          type: String,
          required: false
      },
      routedata_set_name:
      {
          type: String,
          required: false
      }
  },
  watch:
  {
      show:function(shouldShow, prev_val)
      {
          //If the dialog is going to be shown set the date to todays date
          //and display the trips for the current day...
          if(shouldShow === true)
          {
              this.getTrips();
          }
      },
      routedata_id: function(newVal, oldVal)
      {
          this.routedata_id = newVal;
      },
        pickerDate: function(new_date, old_date)
        {
            this.getTrips();
        }
  },
  methods:
  {
      openTripHistory:
      function(trip_data)
      {
          this.history_date =  trip_data.date;
          this.history_reference = trip_data.reference;

          this.$nextTick(function()
          {
              //window.open('s');
              document.getElementById('history_submit_form').submit();
          });
      },
      getTripHistory:
      function(trip_data)
      {
          var date = trip_data.date;
          var reference = trip_data.reference;
          var self = this;

          self.loader_text = "Laster historikk, vennligst vent...";
          self.loading = true;

          this.$http.post('Ajax/realtime_history',
          {
              date:date,
              reference: reference
          })
          .then(function(response)
          {
               self.show_realtime_history = true;
               console.log(response);
          },
          function(error)
          {
              console.log(error);
          })
          .then(function ()
          {
              this.loading = false;
          });
      },
      getTripData:
      function(trip_data)
      {
          var date = trip_data.date;
          var reference = trip_data.reference;
          var self = this;

          self.loader_text = "Laster sanntidsprognoser, vennligst vent...";
          this.loading = true;

          console.log(date);
          console.log(reference);

          this.$http.post('Ajax/realtime_forecast',
          {
              date:date,
              reference: reference
          })
          .then(function(response)
          {
               self.forecast = response.body.forecast;
               console.log(self.forecast);

               self.show_realtime_forecast = true;
          },
          function(error)
          {
              console.log(error);
          })
          .then(function ()
          {
              this.loading = false;
          });
      },
      getTrips:
      function()
      {
          this.trips = [];
          this.loading_trips = true;
          var self = this;

          this.$http.post('Ajax/get_routedata_trip_info',
          {
              id:self.routedata_id,
              date:self.pickerDate,
          })
          .then(function(response)
          {
              self.trips = response.data.data;

              if(response.data.data.length == 0)
              {
                  app.no_trips_found = true;
                  return;
              }

              self.trips = response.data.data;
          },
          function(error)
          {
              console.log(error);
          }).then(function ()
          {
            self.loading_trips = false;
          });
      },
      closeDialog:
      function()
      {
          this.$emit('close-dialog-event',false);
      },
      timer:
      function()
      {
          this.time = moment().format("HH:mm");
      }
  },
  mounted: function ()
  {
      //Add a reference to this object because...IE
      var to = this;
      setInterval(function() { to.timer()}, 1000);
  },
}

</script>
