<template>
        <v-menu
          v-model="show"
          :close-on-content-click="true"
          :nudge-right="40"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          :max-width="max_width"
          :min-width="min_width">
          <v-text-field
            slot="activator"
            v-model="formatted_date"
            :label="label"
            :hint="hint"
            persistent-hint
            prepend-icon="event"
            readonly
          ></v-text-field>
          <v-date-picker v-model="date" :locale="locale"
                         first-day-of-week="1" no-title @input="closeDialog()">
          </v-date-picker>
        </v-menu>
    </v-layout>
  </v-container>
</template>

<script>
  export default {
      props:
      {
          locale:
          {
              type: String,
              required: false,
              default: "nb"
          },
          label:
          {
              type:String,
              required: false,
              default:""
          },
          format:
          {
              type: String,
              required: false,
              default:"DD.MM.YYYY"
          },
          init_date:
          {
              type: String,
              required: false,
              default: ''
          },
          min_width:
          {
              type: String,
              required: false,
              default:"290px"
          },
          max_width:
          {
              type: String,
              required: false,
              default:"290px"
          },
          date_text_format:
          {
              type: String,
              required: false,
              default:""
          },
          hint:
          {
              type: String,
              required: false,
              default:""
          },
          value:
          {

          }
      },
      data: function()
      {
          var init_date = this.init_date;

          if (init_date === '')
          {
              init_date = '';
          }
          else
          {
              init_date = moment(init_date).format(this.format).toString()
          }

          return {
              date:'',
              formatted_date:init_date,
              show:false
          }
      },

    watch:
    {
        date:
        function(new_val)
        {

        }
    },
    computed:
    {

    },
    methods:
    {
        closeDialog:
        function()
        {
            this.formatted_date = moment(this.date).format(this.format);
            this.$emit('input', this.formatted_date);
        }
    }
  }
</script>
