<style>
.v-snack {
    z-index: 10000000 !important;
}

</style>
<template>
    <v-layout row justify-center>

    <v-snackbar
      v-model="show"
      :color="color"
      :timeout="timeout"
      auto-height
      vertical
      top right
      @keydown.enter="closeMessage"
    >
      <div v-html="message"> </div>
      <v-btn v-if="closable === true"
        color="pink"
        dark
        flat
        @click="closeMessage"
      >
        <b>LUKK</b>
      </v-btn>
    </v-snackbar>
</v-layout>

</template>

<script>
  export default {
      props:
      {
          color:
          {
              type: String,
              required: false,
              default:""
          },
          timeout:
          {
              type: Number,
              required: false,
              default:3000
          },
          closable:
          {
              type: Boolean,
              required: false,
              default:false
          },
          message:
          {
              type: String,
              required: false,
              default:""
          },
          value:
          {

          }
      },
      data: function()
      {
          return{
              show:this.value
          }
      },
      watch:
      {
        value: function (val)
        {
            this.show = val;
        }
      },
      methods:
      {
          closeMessage:
          function()
          {
              this.$emit('close-message-event');
          }
      }
  }
</script>
