function showSimpleDialog(event)
{
    switch(event.status)
    {
        case 1:
            popup.$snotify.error(event.message_text, {
              timeout: 10000,
              position:"rightTop",
              showProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true
            });
            break;
        case 2:
            popup.$snotify.warning(event.message_text, {
              timeout: 10000,
              position:"rightTop",
              showProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true
            });
            break;
        case 3:
            popup.$snotify.success(event.message_text, {
              timeout: 10000,
              position:"rightTop",
              showProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true
            });
            break;
        default:
            break;
    }
}

function showYesNoDialog(event)
{
    popup.$snotify.info(event.message_text, event.message_header, {
        position:"rightTop",
        closeOnClick: false,
        timeout: 0,
        buttons: [
          {text: 'Ja', action: function (toast)
                                      {
                                          popup.$snotify.remove(toast.id);
                                          var redirect = window.open('wtw_report_result', '_blank');
                                          redirect.location;
                                      }, bold: true },
          {text: 'Nei', action: function (toast)
                                      {

                                          popup.$snotify.remove(toast.id);
                                      }, bold: true }
        ]
      });
}

function showConfirmDialog(event)
{
    switch(event.status)
    {
        case 1:
            popup.$snotify.error(event.message_text, event.message_header, {
              timeout:0,
              position:"rightTop",
              closeOnClick: true,
              pauseOnHover: true,
              buttons: [
                {text: 'OK', action: (toast) => {popup.$snotify.remove(toast.id);}},
              ]
            });
            break;
        case 2:
            popup.$snotify.warning(event.message_text, {
                timeout:0,
                position:"rightTop",
                closeOnClick: true,
                pauseOnHover: true,
                buttons: [
                  {text: 'OK', action: (toast) => {popup.$snotify.remove(toast.id);}},
                ]
            });
            break;
        case 3:
            popup.$snotify.success(event.message_text, {
                timeout:0,
                position:"rightTop",
                closeOnClick: true,
                pauseOnHover: true,
                buttons: [
                  {text: 'OK', action: (toast) => {popup.$snotify.remove(toast.id);}},
                ]
            });
            break;
        default:
            break;
    }
}


function showNotification(event)
{
    switch(event.message_type)
    {
        case 1:
            showSimpleDialog(event)
            break;
        case 2:
            showConfirmDialog(event);
            break;
        case 3:
            showYesNoDialog(event)
            break;
        default:
            break;
    }
}



const MyPlugin = {
  // The install method is all that needs to exist on the plugin object.
  // It takes the global Vue object as well as user-defined options.
  install(Vue, options) {
      Vue.prototype.$myAddedProperty = this;
      Vue.prototype.$myAddedMethod = function() {

      }

      Vue.prototype.$wtwReportResultHandler = function(event)
      {
          switch(event.event_type)
          {
               case 1:
                   showNotification(event);
                   break;
               case 2:
                   break;
          }
          console.log(event);
      }
  }
};

export default MyPlugin;
