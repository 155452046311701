require('./bootstrap');

import Vuetify from 'vuetify';
import Snotify from 'vue-snotify';
import MyPlugin from './components/EventHandler/WtwReport';
import RoutedataPlugin from './components/EventHandler/Routedata';
import WtwadminPlugin from './components/EventHandler/WtwAdmin';
import UserPlugin from './components/EventHandler/User';
import ConsatSoundFtp from './components/EventHandler/ConsatSoundFtp';
import HistoricalRtReportPlugin from './components/EventHandler/HistoricalRtReport';
import Chartkick from 'vue-chartkick';
import 'chart.js';
import { HotTable } from '@handsontable/vue';
import {LMap, LTileLayer, LMarker, LCircleMarker, LTooltip, LPopup, LIcon, LControl} from 'vue2-leaflet';
import Shepherd from 'vue-shepherd';

window.Vue = require('vue').default;
var vueResource = require('vue-resource').default;

Vue.use(Vuetify);
Vue.use(vueResource);
Vue.use(Vuetify);
Vue.use(Snotify);
Vue.use(MyPlugin);
Vue.use(RoutedataPlugin);
Vue.use(WtwadminPlugin);
Vue.use(UserPlugin);
Vue.use(ConsatSoundFtp);
Vue.use(Chartkick);
Vue.use(HotTable);
Vue.use(Shepherd);
Vue.use(HistoricalRtReportPlugin);

Vue.http.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

Vue.component('simple-dialog', require('./components/common/SimpleDialog.vue').default);
Vue.component('simple-confirm-dialog', require('./components/common/SimpleConfirmDialog.vue').default);
Vue.component('activate-routedata', require('./components/routedata/ActivateRoutedata.vue').default);
Vue.component('deactivate-routedata', require('./components/routedata/DeactivateRoutedata.vue').default);
Vue.component('upload-routedata', require('./components/routedata/UploadRoutedata.vue').default);
Vue.component('routedatatrips', require('./components/routedata/RoutedataTrips.vue').default);
Vue.component('datepicker', require('./components/common/DatePicker.vue').default);
Vue.component('datetimepicker', require('./components/common/DateTimePicker.vue').default);
Vue.component('message', require('./components/common/Message.vue')).default;
Vue.component('itempicker-dialog', require('./components/common/DialogItemPicker.vue').default);
Vue.component('history-trips', require('./components/realtime/HistoryTrips.vue').default);
Vue.component('hot-table', HotTable);
Vue.component('upload-component', require('./components/UploadComponent.vue').default);
Vue.component('map-marker-component', require('./components/MapMarkerComponent.vue').default);
Vue.component('map-marker-ferry-component', require('./components/MapMarkerFerryComponent.vue').default);
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-circle-marker', LCircleMarker);
Vue.component('l-marker', LMarker);
Vue.component('l-tooltip', LTooltip);
Vue.component('l-popup', LPopup);
Vue.component('l-icon', LIcon);
Vue.component('l-control', LControl);

// Time ago library. Used on real time info for vehicles when last update was
// received.
import VueTimeago from 'vue-timeago';
Vue.use(VueTimeago, {
    locales: {
        nb: require('date-fns/locale/nb')
    },
    locale: 'nb'
});

var first_press = false;

window.tutorial = new Vue({
    created()
    {
        this.tour = this.$shepherd(
        {
		useModalOverlay: true,
                defaultStepOptions: {
                    modalOverlayOpeningPadding: 1,
                    cancelIcon: { enabled: false, label: 'Avbryt' },
                    scrollTo: { behavior: 'smooth', block: 'center' },
                    buttons: [
                    {
                        action() {
                            return this.complete();
                        },
                        classes: 'shepherd-button-secondary',
                        text: 'Avslutt'
                    },
                    {
                        action() {
                            return this.back();
                        },
                        classes: 'shepherd-button-primary',
                        text: 'Forrige'
                    },
                    {
                        action() {
                            return this.next();
                        },
                        classes: 'shepherd-button-primary',
                        text: 'Neste'
                    }]
                }
        });

        window.addEventListener('keydown', function(e)
        {
//            console.log('keydown: ', e.keyCode);
            if (e.keyCode === 17)
            {
                // Shift key pressed
                if ((typeof app !== 'undefined') && (typeof app.setMapZoomMode !== 'undefined'))
                {
                    app.setMapZoomMode(true);
                }
            }
        });

        window.addEventListener('keyup', function(e)
        {
//            console.log('keyup: ', e.keyCode);
            if (e.keyCode === 17)
            {
                // Shift key released
                if ((typeof app !== 'undefined') && (typeof app.setMapZoomMode !== 'undefined'))
                {
                    app.setMapZoomMode(false);
                }
            }
        });

        window.addEventListener("keypress", function(e)
        {
            if(first_press)
            {
                first_press = false;
                if (e.keyCode === 104)
                {
                    tutorial.start();
                }
            }
            else
            {
                if(e.keyCode == 104)
                {
                    first_press = true;
                }

                window.setTimeout(function()
                {
                    first_press = false;
                }, 200);
            }
        }.bind(this));
    },
    methods: {
        start: function() {
            if (this.tour.steps.length <= 0)
            {
                alert('Det finnes ingen brukerveiledning for denne siden.');
            }
            else
            {
                if (this.tour.isActive() === false)
                {
                    this.tour.start();
                }
            }
        }
    }

});
