<template>
  <div>
    <v-layout row justify-center>
      <v-dialog v-model="show" persistent max-width="600px" @keydown.esc="closeDialog">
        <v-card>
          <v-card-title>
            <span class="headline">Deaktiver rutedatasett</span>
          </v-card-title>
          <v-card-text>
            <p>
              Dette vil deaktivere hele datasettet på TFT sine tjenester.
              Eventuelle opplastinger til våre partnere vil ikke bli berørt.
            </p>
            <p>
              Er du sikker på du vil deaktivere?
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" flat :disabled="deactivating" @click="deactivateRoutedata">Deaktiver</v-btn>
            <v-btn color="warning" flat :disabled="deactivating" @click="closeDialog">Avbryt</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <simple-dialog v-model="show_dialog"
      :header="header"
      :text="message"
      @close-dialog-event="show_dialog = false"></simple-dialog>
  </div>
</template>


<script>
export default {
    data: function () {
        return {
            deactivating: false,
            show_dialog: false,
            header: "",
            message: ""
        }
    },

    props: {
        show: Boolean,
        routedata_id: {
            type: Number,
            required: true
        },
    },

    methods: {
        closeDialog() {
            this.$emit('close-dialog-event',false);
        },

        deactivateRoutedata() {
            this.deactivating = true;
            this.$http.post(`/routedata/${this.routedata_id}/deactivate`)
                .then((response) => {
                    this.header = "Rutedata deaktiveres";
                    this.message = "Rutedatasettet deaktiveres. Du får en ny melding så snart det har blitt deaktivert.";
                    this.show_dialog = true;
                }, (error) => {
                     this.header = error.body.header;
                    this.message = error.body.message;
                    this.show_dialog = true;
                }).finally(() => {
                    this.deactivating = false;
                    this.closeDialog();
                });
        }
    }
}
</script>
