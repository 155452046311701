<template>
    <v-dialog
      v-model="show"
      :persistent="persistent"
      width="auto "
      @keydown.enter="saveValue"
      @keydown.esc="closeDialog({return:false,value:null})"
      :hide-overlay="!use_overlay"
    >

    <template v-slot:activator="{ on }">
       <v-text-field
         v-model="textfield_value"
         :label="header"
         prepend-icon="access_time"
         @click:prepend="show=true"
         readonly
         v-on="on"
       ></v-text-field>
     </template>


    <v-card>
      <v-card-title class="headline grey lighten-2">{{header}}</v-card-title>

      <v-card-text>
              <v-date-picker v-model="date" locale="nb"
                             first-day-of-week="1"></v-date-picker>
              <v-time-picker v-if="!date_only"
                             v-model="time" format="24hr"></v-time-picker>
      </v-card-text>

      <v-card-actions>
          <v-layout>
            <v-flex xs6 md6 lg6 class="text-xs-center">
                <v-btn block
                color="primary"
                @click="saveValue"
                flat
                >
                Bekreft
                </v-btn>
            </v-flex>

            <v-flex xs6 md6 lg6 class="text-xs-center">
               <v-btn block
               color="warning"
               flat
               @click="closeDialog({return:false,value:null})"
               >
               Avbryt
               </v-btn>
           </v-flex>
        </v-layout>
      </v-card-actions>
    </v-card>
    </v-dialog>
</template>

<script>
  export default {
      props:
      {
          date_only:
          {
              type: Boolean,
              required: false,
              default: false
          },
          use_overlay:
          {
              type: Boolean,
              required: false,
              default: false
          },
          persistent:
          {
              type: Boolean,
              required: false,
              default: true
          },
          header:
          {
              type: String,
              required:false,
              default:""
          },
          init_time:
          {
              type: String,
              required:false,
              default:"00:00"
          },
          init_date:
          {
              type: String,
              required:false,
              default:moment().format('YYYY-MM-DD')
          },
          text_format:
          {
              type: String,
              required:false,
              default:"DD.MM.YYYY HH:mm"
          },
          value_format:
          {
              type: String,
              required:false,
              default:"YYYY-MM-DD HH:mm:ss"
          }
      },
    data ()
    {
      return {
        show: false,
        date: this.init_date,
        time: this.init_time,
        textfield_value:null
      }
    },
    watch:
    {
      show: function(val, oldVal) {
          this.$emit('datetimepicker-event', val);
      }
    },
    methods:
    {
      saveValue:
      function()
      {
          this.textfield_value =  moment(this.date+"T"+this.time).format(this.text_format);
          var retval =  moment(this.date+"T"+this.time).format(this.value_format);
          this.closeDialog({return:true,value:retval});
      },
      closeDialog:
      function(retval)
      {
          this.show = false;
          this.$emit('close-datetimepicker-event',retval);
      }
    }
  }
</script>
