<template>
  <div class="text-xs-center">
    <v-dialog
      v-model="show"
      width="500"
      persistent
      @keydown.esc="onCloseDialog"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Last opp rutedata
        </v-card-title>

        <v-card-text>
          <v-layout row wrap justify-center justify-space-around>
            <v-flex xs5 sm5 md5>
              <v-text-field
                label="Rutedatanavn"
                placeholder="Placeholder"
                outline
                disabled
                :value="regtoppInfo.name"
              />
            </v-flex>

            <v-flex  xs5 sm5 md5>
              <v-text-field
                label="Selskap"
                :value="regtoppInfo.company.name"
                outline
                disabled
              />
            </v-flex>
          </v-layout>

          <v-select
            v-model="partner"
            :items="partners"
            :return-object="true"
            item-text="name"
            label="Velg kilde"
            :loading="ajaxing"
            :disabled="ajaxing"
          >
            <v-list-tile>
              <v-list-tile-action>
                <v-icon :color="partner ? 'indigo darken-4' : ''">mdi-checkbox-blank-outline</v-icon>
              </v-list-tile-action>
              <v-list-tile-title>{{ choose_text }}</v-list-tile-title>
            </v-list-tile>
          </v-select>

          <v-switch v-if="exist_on_server"
            color="indigo darken-3"
            :disabled="uploading"
            v-model="overwrite_existing_routedata"
            label="Overskriv rutedata med samme navn"
          />

          <v-switch v-if="partner && activate_prop"
            color="indigo darken-3"
            :disabled="uploading"
            v-model="activate"
            label="Aktiver rutedatasett eksternt"
          />

          <datepicker v-if="partner && date_prop"
            format="YYYY-MM-DD"
            :disabled="uploading"
            label="Rutedata start"
            v-model="start_date"
            :init_date="regtoppInfo.start"
          />

          <datepicker v-if="partner && date_prop"
            format="YYYY-MM-DD"
            :disabled="uploading"
            label="Rutedata slutt"
            v-model="end_date"
            :init_date="regtoppInfo.end"
          />

          <v-dialog
            v-model="uploading"
            hide-overlay
            persistent
            width="300"
          >
            <v-card color="primary" dark>
              <v-card-text>
                Laster opp rutedata...
                <v-progress-linear indeterminate color="white" class="mb-0" />
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            flat
            :disabled="uploadDisallowed"
            @click="uploadRoutedata"
          >
            Bekreft
          </v-btn>
          <v-btn
            color="warning"
            flat
            :disabled="uploading"
            @click="onCloseDialog"
          >
            Avbryt
          </v-btn>

        </v-card-actions>
      </v-card>


      <simple-dialog
        v-model="invalid_upload"
        :header="upload_error_header"
        :text="upload_error"
        @close-dialog-event="invalid_upload = false"
      ></simple-dialog>
    </v-dialog>
  </div>
</template>


<script>
export default {
    data: function () {
        return {
            activate: false,
            activate_prop: false,
            ajaxing: false,
            choose_text: 'Velg alle',
            date_prop: false,
            dropdown: false,
            end_date: this.regtoppInfo.end,
            exist_on_server: false,
            invalid_upload: false,
            overwrite_existing_routedata: false,

            // Selected partner
            partner: null,

            // All available partners for this route set.
            partners: null,
            start_date: this.regtoppInfo.start,
            upload_error: '',
            upload_error_header: "",
            upload_success: '',
            uploading: false,
        }
    },

    props: {
        regtoppInfo: { type: Object, required: true},
        show: { type: Boolean, required: true }
    },

    computed: {
        uploadDisallowed() {
            return !this.partner
                || this.ajaxing
                || this.invalid_upload
                || this.uploading
                || (this.exist_on_server && !this.overwrite_existing_routedata);
        }
    },

    mounted() {
        this.loadPartners();
    },

    watch: {
        regtoppInfo(newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                this.loadPartners();
                this.exist_on_server = false;
                this.overwrite_existing_routedata = false;
                this.invalid_upload = false;
            }
        },

        partner(newPartner) {
            if (newPartner) {
                this.checkPartnerState();
            }
        }
    },

    methods: {
        checkPartnerState() {
            this.ajaxing = true;
            this.overwrite_existing_routedata = false;
            this.date_prop = false;
            this.activate_prop = false;
            this.activate = false;
            this.$http
                .get(`/routedata/${this.regtoppInfo.id}/partners/${this.partner.id}`)
                .then((response) => {
                    this.exist_on_server = !!response.data.exists;
                    // Set partner properties
                    response.data.partnerProperties.forEach((property) => {
                        switch (property.id) {
                            case 1:
                                // Partner support date overriding
                                this.date_prop = true;
                                break;
                            case 2:
                                // Partner routedata activation
                                this.activate_prop = true;
                                break;
                            default:
                                break;
                        }
                    });
                    this.invalid_upload = false;
                }).catch((error) => {
                    this.upload_error_header =`${error.status}: ${error.statusText}`;
                    this.upload_error = error.body.message;
                    this.partner = null;
                    this.invalid_upload = true;
                }).finally(() => this.ajaxing = false);
        },

        uploadRoutedata() {
            this.uploading = true;
            this.$http.put(`/routedata/${this.regtoppInfo.id}/partners/${this.partner.id}`, {
                start_date: this.start_date,
                end_date: this.end_date,
                activate: this.activate
            }).then((response) => {
                this.closeDialog(response.data);
            }).catch((error) => {
                this.upload_error = error.body.message;
                this.upload_error_header = error.body.header;
                this.invalid_upload = true;
            }).finally(() => this.uploading = false);
        },

        closeDialog(data = null) {
            this.$emit('close-dialog-event', data);
        },

        onCloseDialog(event) {
            this.closeDialog();
        },

        loadPartners() {
            this.ajaxing = true;
            this.partner = null;
            this.partners = [];
            this.$http
                .get(`/routedata/${this.regtoppInfo.id}/partners`)
                .then((response) => {
                    this.partners = response.data.sort((p1, p2) => p1.name.localeCompare(p2.name));
                })
                .finally(() => this.ajaxing = false)
        }
    }
}
</script>
