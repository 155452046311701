<template>
    <l-marker
        ref="marker_ref"
        @popupopen="openPopup"
        @popupclose="closePopup"
        :lat-lng="[item.latitude, item.longitude]">
        <l-icon
            :icon-url="null"
            :icon-anchor="[155 * scale, 155 * scale]">
            <svg
                :height="310 * scale"
                :width="310 * scale">
                <g
                    :transform="setScale()">
                    <circle
                        :fill="backgroundColor"
                        r="154"
                        cx="155"
                        cy="155">
                    </circle>
                    <text
                        font-weight="bold"
                        text-anchor="middle"
                        font-size="100"
                        class="marker-label"
                        :fill="textColor"
                        x="155"
                        y="190">{{ item.line }}
                    </text>
                    <circle
                        fill="none"
                        :stroke="getStatusColor()"
                        stroke-width="30"
                        r="135"
                        cx="155"
                        cy="155">
                    </circle>

                    <!-- Heading arrow -->
                    <path
                        d="M155 0 L125 40 L185 40 Z"
                        :fill="backgroundColor"
                        :transform="setHeading(item.heading)">
                    </path>
                    <path
                        d="M155 5 L130 38 L180 38 Z"
                        :fill="headingColor"
                        :transform="setHeading(item.heading)">
                    </path>
                </g>
            </svg>
        </l-icon>
        <l-popup
            ref="popup_ref"
            :options="popupOptions">
            <v-card
                flat
                class="overflow-hidden">
                <v-card-title
                    class="headline px-0 py-0">
                    <v-layout align-center fill-height>
                        <v-btn
                            color="blue"
                            class="white--text font-weight-bold disable-events"
                            small
                            fab>{{ item.line }}
                        </v-btn>
                        <span>{{ item.name }}</span>
                    </v-layout>
                </v-card-title>
                <v-divider
                    class="my-2">
                </v-divider>

                <v-tabs
                  v-model="tab"
                >
                  <v-tab key="1"ripple>
                    <b>Sanntidsinformasjon</b>
                  </v-tab>
                  <v-tab key="2" ripple @click="showForecast">
                    <b>Sanntidsprognoser</b>
                  </v-tab>

                  <v-tab-item key="1">

                    <v-divider
                        class="my-2">
                    </v-divider>

                    <v-layout row align-center justify-space-around>
                        <v-icon large>directions_bus</v-icon>
                        <v-icon large>update</v-icon>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6>
                            <v-text-field
                                label="Neste stopp"
                                v-model="item.nextStop"
                                :loading="item.isAtStop === true"
                                class="mx-4 disable-events"
                                hide-details
                                readonly>
                            </v-text-field>
                        </v-flex>
                        <v-flex xs6>
                            <v-text-field
                                label="Forsinkelse"
                                :value="getDelayTime(item.delay)"
                                class="mx-4 disable-events"
                                hide-details
                                readonly>
                            </v-text-field>
                        </v-flex>
                    </v-layout>
                    <v-layout row class="mt-3">
                        <v-flex xs6>
                            <v-text-field
                                label="Distanse"
                                v-model="item.nextStopDistance"
                                class="mx-4 disable-events"
                                hide-details
                                readonly>
                            </v-text-field>
                        </v-flex>
                        <v-flex xs6>
                            <v-text-field
                                label="Buss-ID"
                                :value="getVehicleID()"
                                class="mx-4 disable-events"
                                hide-details
                                readonly>
                            </v-text-field>
                        </v-flex>
                    </v-layout>
                    <v-layout column class="mt-4">
                        <v-layout>
                            <span class="pax-flow-label">Passasjerflyt:</span>
                            <span>
                                <v-icon
                                    class="ml-2"
                                    small>arrow_upward
                                </v-icon>
                                <strong>{{ item.paxFlowOn }}</strong>
                                <v-icon
                                    class="ml-2"
                                    small>arrow_downward
                                </v-icon>
                                <strong>{{ item.paxFlowOff }}</strong>
                            </span>
                        </v-layout>
                        <v-layout row>
                            <v-progress-linear
                                color="green"
                                height="6"
                                :value="item.paxOccupancy"
                                class="mx-4 my-1 pb-0">
                            </v-progress-linear>
                        </v-layout>
                    </v-layout>
                  </v-tab-item>

                  </v-tab>
                  <v-tab-item key="2">

                    <template>
                      <v-data-table hide-headers
                                    rows-per-page-text=""
                                    no-results-text="Fant ingen prognoser"
                                    :rows-per-page-items="[4]"
                                    :headers="forecast_headers"
                                    :items="forecast_data"
                                    class="elevation-1">

                      <template v-slot:items="props">
                        <td v-if="hasDepartured(props.item.actual_departure)"><strike>{{ props.item.stop_point_name }}</strike>
                        <td v-else><b>{{ props.item.stop_point_name }}</b></td>
                        </td>

                        <td v-if="hasDepartured(props.item.actual_departure)"><strike>{{ props.item.actual_departure }}</strike>
                        <td v-else><b>{{ props.item.actual_departure }}</b></td>
                        </td>
                      </template>

                      <template v-slot:pageText="props">
                         Holdeplass {{props.pageStart}} - {{props.pageStop}} av totalt {{props.itemsLength}}
                      </template>

                    </v-data-table>
                  </template>

                  </v-tab-item>

                </v-tabs>

                <v-card-actions
                    class="pt-4 pb-1">
                    <div class="box">
<!--                    <v-checkbox
                        label="Følg buss"
                        v-model="popupOptions.keepInView"
                        @change="toggleFollowMode()"
                        :disabled="true"
                        class="my-0 py-0"
                        hide-details>
                    </v-checkbox>-->
                    </div>
                    <div class="box">
                        <v-layout row align-center justify-center>
                            <v-btn
                                @click="closePopup()"
                                color="primary"
                                round>Lukk
                            </v-btn>
                        </v-layout>
                    </div>
                    <div class="box">
                        <span :title="item.timestamp">Sist oppdatert:<br>
                            <timeago
                                :datetime="item.timestamp"
                                :auto-update="1"
                                :converter="timeagoStrictConverter"></timeago>
                            siden
                        </span>
                    </div>
                </v-card-actions>


            </v-card>
        </l-popup>
    </l-marker>
</template>

<script>
import distanceInWordsStrict from 'date-fns/distance_in_words_strict';
export default {
    data: function() {
        return {
            popupOptions: {
                minWidth: 400,
                closeButton: false,
                keepInView: true
            },
            forecast_headers:[
                              {
                                text: 'Holdeplass',
                                sortable: false,
                                value: 'actual_departure'
                              },
                              { text: 'Avgang',
                                align: 'left',
                                sortable: false,
                                value: 'stop_point_name'
                              }
                            ],
            forecast_data:[],
            tab:0
        }
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        scale: {
            type: Number,
            required: false,
            default: 0.15
        },
        textColor: {
            type: String,
            required: false,
            default: 'white'
        },
        backgroundColor: {
            type: String,
            required: false,
            default: 'black'
        },
        circleColor: {
            type: String,
            required: false,
            default: '#ccc'
        },
        headingColor: {
            type: String,
            required: false,
            default: 'white'
        },
        colorDelayed: {
            type: String,
            required: false,
            default: 'DodgerBlue'
        },
        colorPrecise: {
            type: String,
            required: false,
            default: '#5c5'
        },
        colorEarly: {
            type: String,
            required: false,
            default: 'red'
        }
    },
    methods: {
        hasDepartured: function(departure_time)
        {
            departure_time = moment(departure_time,"HH:mm");

            if(departure_time.isValid() === false)
            {
              return false;
            }

            if(moment(moment(),"HH:mm").isBefore(departure_time))
            {
              return false;
            }

            return true;
        },
        showForecast: function()
        {
            this.tab = 1;
            var reference = this.item.reference;
            var obj = this;

            this.$http.post('Ajax/realtime_forecastp',
            {
                reference: reference
            })
            .then(function(response)
            {
                obj.forecast_data = [];
                obj.forecast_data = response.body.forecast;
                // console.log(obj.forecast_data);
            },
            function(error)
            {


            }).then(function ()
            {

            });

        },
        setScale: function() {
            return 'scale(' + this.scale + ')';
        },
        setHeading: function(heading) {
            return 'rotate(' + heading + ', 155, 155)';
        },
        getDelayTime: function(value) {
            var res = ((value < 0) ? "-" : "");
            if (value < -30)
            {
                return "Tidsregulering";
            }
            var time = Math.abs(value);
            var mins = Math.floor((time % 3600) / 60);
            var secs = Math.floor(time % 60);
            res += "" + mins + ":" + (secs < 10 ? "0" : "");
            res += "" + secs;
            return res;
        },
        getStatusColor: function() {
            if (isNaN(this.item.delay))
            {
                return this.circleColor;
            }
            else if (this.item.delay < -30)
            {
                return this.colorEarly;
            }
            else if (this.item.delay > 180)
            {
                return this.colorDelayed;
            }
            else
                return this.colorPrecise;
        },
        getVehicleID: function() {
            return this.item.id.substr(6);
        },
        openPopup: function(e) {
            app.openPopup(this.item.id, this.item.line);
        },
        closePopup: function(e) {
            app.closePopup();
        },
        toggleFollowMode: function() {
/*            console.log(this.$refs.popup_ref);
            var popup = this.$refs.popup_ref.mapObject;
            app.updatePopup(popup);
            return;
            console.log(popup.options.keepInView);
//            popup.options.minWidth = 600;
            popup.closePopup();
*/        },
        timeagoStrictConverter: function (date, locale, options) {
            return distanceInWordsStrict(date, new Date(), {locale});
        }
    }
}
</script>

<style scoped>
.disable-events
{
    pointer-events: none;
}

.pax-flow-label
{
    margin-left: 0.5em;
}

.box
{
    box-sizing: border-box;
    float: left;
    width: 33.33%;
    text-align: center;
}
</style>

