import Centrifuge from 'centrifuge';

window._ = require('lodash');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Centrifugo isn't supported by Laravel's client side Echo package, so a bit
 * manual labor is required here.
 */
window.centrifugo = new Centrifuge(process.env.MIX_CENTRIFUGO_WS, { subscribeEndpoint: '/broadcasting/auth' });
if (window.broadcast) {
    window.centrifugo.setToken(window.broadcast.token);
    window.centrifugo.connect();
}

window.jQuery = require('jquery');
window.$ = window.jQuery;

window.Popper = require('popper.js');
require('bootstrap');
require('@fortawesome/fontawesome-free');
window.Noty = require('noty');
window.moment = require('moment');
window.Dropzone = require('dropzone');
window.b64toBlob = require('b64-to-blob');
window.Chart = require('chart.js');
