function handleData(data)
{
//    console.log(data);
    var hasRecipient = true;

    if ((typeof app === 'undefined') || (typeof app.summaryTable === 'undefined'))
    {
        // Cannot update table when the user loads a different page
        hasRecipient = false;
    }
    else
    {
        // ... or reloads the soundfile upload page
        hasRecipient = app.savingData;
    }

    if (data.length === undefined)
    {
        // Show messages only when summaryTable is inaccessible
        if (hasRecipient === false)
        {
            if (data.success === true)
            {
                popup.$snotify.success(data.message, {
                    timeout: 10000,
                    position:"rightTop",
                    showProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true
                });
            }
            else
            {
                popup.$snotify.error(data.message, {
                    timeout: 10000,
                    position:"rightTop",
                    showProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true
                });
            }
        }
        return;
    }

    if (hasRecipient === true)
    {
        // Update table
        for (var i = 0; i < data.length; i++)
        {
            var index = data[i].index;
            if (app.summaryTable.length > index)
            {
                app.summaryTable[index].status = data[i].dbStatus;
                app.summaryTable[index].extStatus = data[i].ftpStatus;
            }
        }
    }
}


const ConsatSoundFtp = {
    // The install method is all that needs to exist on the plugin object.
    // It takes the global Vue object as well as user-defined options.
    install(Vue, options) {
        Vue.prototype.$myAddedProperty = this;
        Vue.prototype.$myAddedMethod = function() {

        }

        Vue.prototype.$consatSoundFtpHandler = function(event)
        {
            //console.log(event);
            switch(event.event_type)
            {
                case 1:
                    //showNotification(event);
                    break;
                case 2:
                    handleData(event.data);
                    break;
            }
        }
    }
};

export default ConsatSoundFtp;
