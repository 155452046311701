<template>
  <div>


    <v-dialog
      v-model="activating"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Aktiverer rutedata...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-layout row justify-center>
      <v-dialog v-model="show" persistent max-width="600px" @keydown.esc="closeDialog">
        <v-card>
          <v-card-title>
            <span class="headline">Aktiver rutedatasett</span>
          </v-card-title>
          <v-card-text>

              <v-menu
                 ref="date_menu"
                 v-model="date_menu"
                 :close-on-content-click="true"
                 :nudge-right="40"
                 lazy
                 transition="scale-transition"
                 offset-y
                 full-width
                 max-width="290px"
                 min-width="290px">

                 <v-text-field
                   slot="activator"
                   v-model="picked_date"
                   label="Date"
                   hint="Velg dato datasettet skal være aktivt fra"
                   persistent-hint
                   prepend-icon="event"
                   ></v-text-field>

                 <v-date-picker v-model="date"
                                :max="routedata_max_date"
                                :min="routedata_min_date"
                                no-title @input="date_menu = false"></v-date-picker>
               </v-menu>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" flat :disabled="activating" @click="activateRoutedata">Aktiver</v-btn>
            <v-btn color="warning" flat :disabled="activating" @click="closeDialog">Avbryt</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>


    <simple-dialog v-model="show_dialog"
                  :header="header"
                  :text="message"
                  @close-dialog-event="show_dialog = false"></simple-dialog>
</div>
</template>

<script>
export default
{
    data: function ()
    {
      return {
          date: null,
          picked_date: null,
          date_menu: false,
          activating:false,
          show_dialog:false,
          header:"",
          message:""
      }
    },
    props:
    {
        show: Boolean,
        routedata_id:
        {
           type: Number,
           required: true
        },
        routedata_max_date:
        {
            type: String,
            default:moment()
        },
        routedata_min_date:
        {
            type: String,
            required: true
        }
    },
    watch:
    {
       date:function(val)
       {
           this.picked_date = val ? moment(val).format("DD.MM.YYYY") : null;
       },
    },
    methods:
    {
        closeDialog()         {
            this.$emit('close-dialog-event',false);
        },

        activateRoutedata() {
            var routedata_activation_date = this.picked_date ? moment(this.picked_date,"DD.MM.YYYY").format("YYYY-MM-DD") : null;
            this.activating = true;
            this.$http.post(`/routedata/${this.routedata_id}/activate`, { activation_date: routedata_activation_date })
                .then((response) => {
                    this.header = "Rutedata aktiveres";
                    this.message = "Rutedatasettet aktiveres. Du får en ny melding så snart det har blitt aktivert.";
                    this.show_dialog = true;
                }).catch((error) => {
                    this.header = error.body.header;
                    this.message = error.body.message;
                    this.show_dialog = true;
                }).finally(() => {
                    this.activating = false;
                    this.closeDialog();
                });
        }
    }
}
</script>
