<style scoped>
.disable-events
{
    pointer-events: none;
}

.pax-flow-label
{
    margin-left: 0.5em;
}

.box
{
    box-sizing: border-box;
    float: left;
    width: 33.33%;
    text-align: center;
}
</style>

<template>
    <l-marker
        ref="marker_ref"
        @popupopen="openPopup"
        @popupclose="closePopup"
        :lat-lng="[item.latitude, item.longitude]">
        <l-icon
            :icon-url="null"
            :icon-anchor="[250 * scale, 250 * scale]">
            <svg
                :height="500 * scale"
                :width="500 * scale">
                <g
                    :transform="setScale()">
                    <path
                        d="M250 4 L385 137 L385 435 q -135 40 -270 0 L115 137 Z"
                        :fill="backgroundColor"
                        :transform="setHeading(item.heading)">
                    </path>
                    <path
                        d="M250 10 L380 140 L380 430 q -130 40 -260 0 L120 140 Z"
                        :fill="getStatusColor()"
                        :transform="setHeading(item.heading)">
                    </path>
                    <path
                        d="M250 55 L350 150 L350 400 q -100 30 -200 0 L150 150 Z"
                        :fill="backgroundColor"
                        :transform="setHeading(item.heading)">
                    </path>
                    <text
                        font-weight="bold"
                        text-anchor="middle"
                        font-size="110"
                        fill="white"
                        x="250"
                        y="290">{{ item.line }}
                    </text>
                </g>
            </svg>
        </l-icon>
        <l-popup
            ref="popup_ref"
            :options="popupOptions">
            <v-card
                flat
                class="overflow-hidden">
                <v-card-title
                    class="headline px-0 py-0">
                    <v-layout align-center fill-height>
                        <v-btn
                            color="blue"
                            class="white--text font-weight-bold disable-events"
                            small
                            fab>{{ item.line }}
                        </v-btn>
                        <span>{{ item.name }}</span>
                    </v-layout>
                </v-card-title>
                <v-divider
                    class="my-2">
                </v-divider>
                <v-layout row align-center justify-space-around>
                    <v-icon large>directions_boat</v-icon>
                    <v-icon large>update</v-icon>
                </v-layout>
                <v-layout row>
                    <v-flex xs6>
                        <v-text-field
                            label="Neste stopp"
                            v-model="item.nextStop"
                            :loading="item.isAtStop === true"
                            class="mx-4 disable-events"
                            hide-details
                            readonly>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs6>
                        <v-text-field
                            label="Forsinkelse"
                            :value="getDelayTime(item.delay)"
                            class="mx-4 disable-events"
                            hide-details
                            readonly>
                        </v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout row class="mt-3">
                    <v-flex xs6>
                        <v-text-field
                            label="Distanse"
                            v-model="item.nextStopDistance"
                            class="mx-4 disable-events"
                            hide-details
                            readonly>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs6>
                        <v-text-field
                            label="Ferge-ID"
                            :value="getVehicleID()"
                            class="mx-4 disable-events"
                            hide-details
                            readonly>
                        </v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout column class="mt-4">
                    <v-layout>
                        <span class="pax-flow-label">Passasjerflyt:</span>
                        <span>
                            <v-icon
                                class="ml-2"
                                small>arrow_upward
                            </v-icon>
                            <strong>{{ item.paxFlowOn }}</strong>
                            <v-icon
                                class="ml-2"
                                small>arrow_downward
                            </v-icon>
                            <strong>{{ item.paxFlowOff }}</strong>
                        </span>
                    </v-layout>
                    <v-layout row>
                        <v-progress-linear
                            color="green"
                            height="6"
                            :value="item.paxOccupancy"
                            class="mx-4 my-1 pb-0">
                        </v-progress-linear>
                    </v-layout>
                </v-layout>
                <v-card-actions
                    class="pt-4 pb-1">
                    <div class="box">
<!--                    <v-checkbox
                        label="Følg ferge"
                        v-model="popupOptions.keepInView"
                        @change="toggleFollowMode()"
                        :disabled="true"
                        class="my-0 py-0"
                        hide-details>
                    </v-checkbox>-->
                    </div>
                    <div class="box">
                        <v-layout row align-center justify-center>
                            <v-btn
                                @click="closePopup()"
                                color="primary"
                                round>Lukk
                            </v-btn>
                        </v-layout>
                    </div>
                    <div class="box">
                        <span :title="item.timestamp">Sist oppdatert:<br>
                            <timeago
                                :datetime="item.timestamp"
                                :auto-update="1"
                                :converter="timeagoStrictConverter"></timeago>
                            siden
                        </span>
                    </div>
                </v-card-actions>
            </v-card>
        </l-popup>
    </l-marker>
</template>

<script>
import distanceInWordsStrict from 'date-fns/distance_in_words_strict';
export default {
    data: function() {
        return {
            popupOptions: {
                minWidth: 400,
                closeButton: false,
                keepInView: true
            }
        }
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        scale: {
            type: Number,
            required: false,
            default: 0.15
        },
        textColor: {
            type: String,
            required: false,
            default: 'white'
        },
        backgroundColor: {
            type: String,
            required: false,
            default: 'black'
        },
        rimColor: {
            type: String,
            required: false,
            default: '#ccc'
        },
        colorDelayed: {
            type: String,
            required: false,
            default: 'DodgerBlue'
        },
        colorPrecise: {
            type: String,
            required: false,
            default: '#5c5'
        },
        colorEarly: {
            type: String,
            required: false,
            default: 'red'
        }
    },
    methods: {
        setScale: function() {
            return 'scale(' + this.scale + ')';
        },
        setHeading: function(heading) {
            return 'rotate(' + heading + ', 250, 250)';
        },
        getDelayTime: function(value) {
            var res = ((value < 0) ? "-" : "");
            if (value < -30)
            {
                return "Tidsregulering";
            }
            var time = Math.abs(value);
            var mins = Math.floor((time % 3600) / 60);
            var secs = Math.floor(time % 60);
            res += "" + mins + ":" + (secs < 10 ? "0" : "");
            res += "" + secs;
            return res;
        },
        getStatusColor: function() {
            if (isNaN(this.item.delay))
            {
                return this.rimColor;
            }
            else if (this.item.delay < -30)
            {
                return this.colorEarly;
            }
            else if (this.item.delay > 180)
            {
                return this.colorDelayed;
            }
            else
                return this.colorPrecise;
        },
        getVehicleID: function() {
            return this.item.id.substr(6);
        },
        openPopup: function(e) {
            app.openPopup(this.item.id, this.item.line);
        },
        closePopup: function(e) {
            app.closePopup();
        },
        toggleFollowMode: function() {
/*            console.log(this.$refs.popup_ref);
            var popup = this.$refs.popup_ref.mapObject;
            app.updatePopup(popup);
            return;
            console.log(popup.options.keepInView);
//            popup.options.minWidth = 600;
            popup.closePopup();
*/        },
        timeagoStrictConverter: function (date, locale, options) {
            return distanceInWordsStrict(date, new Date(), {locale});
        }
    }
}
</script>
