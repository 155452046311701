<template>
    <div id="app">
        <v-tooltip top>
            <vue-dropzone
                slot="activator"
                ref="vue2_dropzone"
                id="customdropzone"
                @vdropzone-file-added="fileAdded"
                @vdropzone-removed-file="fileRemoved"
                @vdropzone-complete="complete"
                @vdropzone-complete-multiple="completeMultiple"
                @vdropzone-max-files-exceeded="maxFilesExceeded"
                @vdropzone-error="error"
                @vdropzone-sending-multiple="sendingMultiple"
                @vdropzone-success-multiple="successMultiple"
                @vdropzone-queue-complete="queueComplete"
                @vdropzone-thumbnail="thumbnail"
                :include-styling="false"
                :headers="{ 'X-CSRF-TOKEN': csrf }"
                :useCustomSlot="true"
                :options="config">
                <div class="dropzone-custom-content" style="text-align:center; cursor:default">
                  <h3 class="dropzone-custom-title">{{upload_box_header}}</h3>
                  <div class="subtitle">
                    {{upload_box_text}}
                  </div>
                </div>
            </vue-dropzone>
            <span>{{ config.dictDefaultMessage }}</span>
        </v-tooltip>
    </div>
</template>

<script>
import vueDropzone from "vue2-dropzone";

export default {
    data: function() {
        return {
            config: {
//                url: '',
                uploadMultiple: true,
//                autoProcessQueue: false,
                parallelUploads: 10,
//                maxFilesize: 1,         // MB
//                maxFiles: 100,
                timeout: 600000,
//                acceptedFiles: '',
//                addRemoveLinks: true,
                removeUnsupported: false,
                previewTemplate: this.template(),
                dictDefaultMessage: "Drop files here"
            }
        }
    },
    components: {
        vueDropzone
    },
    props: {
        csrf: {
            type: String,
            required: true
        },
        url: {
            type: String,
            required: true
        },
        maxFilesize: {
            type: Number,
            required: true
        },
        maxFiles: {
            type: Number,
            required: true
        },
        acceptedFiles: {
            type: String,
            required: true
        },
        addRemoveLinks: {
            type: Boolean,
            required: true
        },
        defaultMessage: {
            type: String,
            required: true
        },
        invalidFileTypeMessage: {
            type: String,
            required: true
        },
        maxFilesExceededMessage: {
            type: String,
            required: true
        },
        fileTooBigMessage: {
            type: String,
            required: true
        },
        removeFileText: {
            type: String,
            required: true
        },
        upload_box_header: {
            type: String,
            required: false,
            default:""
        },
        upload_box_text: {
            type: String,
            required: false,
            default:""
        },
        auto_process_queue:
        {
            type: Boolean,
            required: false,
            default:false
        },
    },
    created: function() {
        this.config.url = this.url;
        this.config.maxFiles = this.maxFiles;
        this.config.maxFilesize = this.maxFilesize;
        this.config.acceptedFiles = this.acceptedFiles;
        this.config.addRemoveLinks = this.addRemoveLinks;
        this.config.dictDefaultMessage = this.defaultMessage;
        this.config.dictInvalidFileType = this.invalidFileTypeMessage;
        this.config.dictMaxFilesExceeded = this.maxFilesExceededMessage;
        this.config.dictFileTooBig = this.fileTooBigMessage;
        this.config.dictRemoveFile = this.removeFileText;
        this.config.autoProcessQueue = this.auto_process_queue;
    },

    methods: {
        fileAdded: function(file) {
            var acceptedFiles = this.$refs.vue2_dropzone.getAcceptedFiles();
            app.fileAdded(file, acceptedFiles);
            if (!file.accepted) {
                return;
            }
            Vue.nextTick(() => {
                var accepted = this.$refs.vue2_dropzone.getAcceptedFiles();
                app.setAcceptedCount(accepted.length);
            })
        },
        fileRemoved: function(file, error, xhr) {
            app.fileRemoved(file, error, xhr);
        },
        removeFile: function(file) {
            this.$refs.vue2_dropzone.removeFile(file);
        },
        clearQueue: function() {
            this.$refs.vue2_dropzone.removeAllFiles();
        },
        startUpload: function() {
            this.$refs.vue2_dropzone.processQueue();
        },
        complete: function(response) {
            app.complete(response);
        },
        completeMultiple: function(response) {
            app.completeMultiple(response);
        },
        maxFilesExceeded: function(file) {
            app.maxFilesExceeded(file);
        },
        error: function(file, message, xhr) {
            app.uploadError(file, message, xhr);
        },
        sendingMultiple: function(files, xhr, formData) {
            formData.append('uid', app.uploadUID);
            formData.append('_token', this.csrf);
        },
        successMultiple: function(files, response) {
            app.successMultiple(files, response);
        },
        queueComplete: function() {
            app.queueComplete();
        },
        template: function () {
            return `<div class="dz-preview dz-file-preview">
                    <div class="dz-details">
                        <div class="dz-size"><span data-dz-size></span></div>
                        <div class="dz-filename"><span data-dz-name></span></div>
                    </div>
                    <div class="dz-error-message dz-hidden">
                        <span data-dz-errormessage></span>
                    </div>
                    <div class="dz-success-mark"><i class="fa fa-check"></i></div>
                    <div class="dz-error-mark"><i class="fa fa-close"></i></div>
                    </div>`;
        },
        thumbnail: function(file, dataUrl) {
            // Required method
        }
    }
};
</script>
