<template>
  <v-layout row justify-center>
    <v-dialog scrollable max-width="300px" v-model="show">
      <v-card>
        <v-card-title>Select Country</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px;">
          <v-radio-group column v-for="(item,index) in items" v-model="report_id" :key="index">
            <v-radio v-if="attribute === undefined":label='item["name"]' :value="index"></v-radio>
            <v-radio v-else ="attribute !== undefined":label='item[attribute]' :value="index"></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-layout>
            <v-flex xs6 md6 lg6 class="text-xs-center">
                <v-btn block
                color="primary"
                flat
                @click="closeDialog(true,report_id)"
                >
                {{agree_button_text}}
                </v-btn>
            </v-flex>

            <v-flex xs6 md6 lg6 class="text-xs-center">
               <v-btn block
               color="warning"
               flat
               @click="closeDialog(false,report_id)"
               >
               {{abort_button_text}}
               </v-btn>
           </v-flex>

        </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
  export default {
      props:
      {
          items:
          {
              type: Array,
              required: true,
              default: function ()
              {
                return [];
              }
          },
          attribute:
          {
              type: String,
              required: false,
              default: undefined
          },
          persistent:
          {
              type: Boolean,
              required: false,
              default: true
          },
          abort_button_text:
          {
              type: String,
              required: false,
              default:"Avbryt"
          },
          agree_button_text:
          {
              type: String,
              required: false,
              default: "OK"
          },
          value:
          {
          }
      },
    data ()
    {
          return {
            report_id:undefined,
            show: this.value
          }
    },
    watch:
    {
      value: function (val)
      {
          this.show = val;
          console.log("Value is=" + val);
          this.$emit('input', val);
      }
    },
    methods:
    {
        closeDialog:
        function(retval,index)
        {
            //this.show = false;
            //console.log(this.show);
            this.$emit('close-dialog-event',{'value':retval,'index':index});
        }
    }
  }
</script>
