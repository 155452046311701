<template>
  <v-layout row justify-center>

    <v-dialog
      v-model="show"
      :max-width="max_width"
      :persistent="true"
      hide-overlay
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">{{header}}</v-card-title>

        <v-card-text v-if="text.length > 0" v-html="text" />
        <v-card-text v-else>
          <slot></slot>
        </v-card-text>

        <v-card-actions>
          <v-layout>
            <v-flex xs6 md6 lg6 class="text-xs-center">
              <v-btn block
                color="primary"
                flat
                @click="closeDialog(true)"
              >
                {{agree_button_text}}
              </v-btn>
            </v-flex>

            <v-flex xs6 md6 lg6 class="text-xs-center">
              <v-btn block
                color="warning"
                flat
                @click="closeDialog(false)"
              >
                {{abort_button_text}}
              </v-btn>
            </v-flex>

          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
  export default {
      props:
      {
          object:
          {
              type: Object,
              required: false,
              default: function (){
                return null;
              }
          },
          header:
          {
              type: String,
              required: false,
              default: ""
          },
          text:
          {
              type:String,
              required: false,
              default:""
          },
          abort_button_text:
          {
              type: String,
              required: false,
              default:"Avbryt"
          },
          agree_button_text:
          {
              type: String,
              required: false,
              default: "OK"
          },
          width:
          {
              type: String,
              required: false,
              default: "290"
          },
          value:
          {
          }
      },
      data ()
      {
        return {
          show: this.value,
          obj: this.object,
          max_width: this.width
        }
      },
      watch:
      {
        value: function (val)
        {
          this.show = val;
        },
        object: function (val)
        {
          this.obj = val;
        }
      },
    methods:
    {
      closeDialog:
      function(retval)
      {
          this.$emit('close-dialog-event',{'value':retval,'object':this.obj});
      }
    }
  }
</script>
