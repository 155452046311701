function showSimpleDialog(event) {
    const snotifyType = { '1': 'error', '2': 'warning', '3': 'success' }[event.status];
    if (snotifyType) {
        popup.$snotify[snotifyType](event.message_text, {
            timeout: 10000,
            position: 'rightTop',
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
        });
    }
}

function showYesNoDialog(event) {
    popup.$snotify.info(event.message_text, event.message_header, {
        position: 'rightTop',
        closeOnClick: false,
        timeout: 0,
        buttons: [{
            text: 'Ja',
            action(toast) {
                popup.$snotify.remove(toast.id);
                window.open('wtw_report_result', '_blank');
            },
            bold: true
        }, {
            text: 'Nei',
            action(toast) {
                popup.$snotify.remove(toast.id);
            },
            bold: true
        }],
    });
}

function showConfirmDialog(event) {
    popup.$snotify.info(event.message_text, event.message_header, {
        position: 'rightTop',
        closeOnClick: false,
        timeout: 0,
        buttons: [{
            text: 'OK',
            action(toast) {
                popup.$snotify.remove(toast.id);
            },
            bold: true,
        }],
    });
}


function showNotification(event) {
    switch(event.message_type) {
    case 1:
        showSimpleDialog(event);
        break;
    case 2:
        showConfirmDialog(event);
        break;
    case 3:
        showYesNoDialog(event);
        break;
    }
}



const RoutedataPlugin = {
    // The install method is all that needs to exist on the plugin object.  It
    // takes the global Vue object as well as user-defined options.
    install(Vue) {
        Vue.prototype.$routedataHandler = function(event) {
            if (event.event_type === 1) {
                showNotification(event);
            }
        };
    }
};

export default RoutedataPlugin;
